import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../layouts'
import Feature from '../components/Feature'
import Sidebar from '../components/Sidebar'
import Accreditations from '../components/accreditations'

const meta = {
  title: 'Electrical Testing Services in Essex',
  url: 'https://www.maxpowerlimited.com/electrical-testing-services-in-essex/',
}

const EicrAndEmergencyLightTestingInBuckinghamshirePage = props => (
  <Layout {...props}>
    <Helmet
      title={meta.title}
      meta={[
        { itemprop: 'name', content: meta.title },
        { property: 'og:title', content: meta.title },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:url', content: meta.url },
      ]}
      link={[{ rel: 'canonical', href: meta.url }]}
    />

    <Feature title={meta.title} />

    <div className="page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <h2>Fixed Wire Testing Services in Essex</h2>

            <p>
              Maxpower Limited specialise in providing electrical compliance
              testing and maintenance throughout Essex and the UK.Clients are
              comprised of care homes, local and public authorities, schools,
              universities, and colleges together with a wide variety of
              industrial, leisure and commercial / FM organisations.Many ongoing
              relationships and repeat business have consolidated every aspect
              of the services provided.
            </p>

            <ul className="regional-services">
              <li>
                <FontAwesomeIcon icon="check" />
                <strong>National Coverage</strong> We provide Fixed Wire Testing
                and Inspections across Essex and the entire United Kingdom,
                whilst maintaining local, competitive rates.
              </li>

              <li>
                <FontAwesomeIcon icon="check" />
                <strong>Fully Accredited</strong> Maxpower Limited are fully
                approved by various organisations, including the Stroma, CHAS
                and Trustmark.
              </li>

              <li>
                <FontAwesomeIcon icon="check" />
                <strong>Quality Service</strong> Our teams of DBS cleared,
                expert engineers and our experienced support staff, along with
                our unique reporting system, ensure a seamless service.
              </li>

              <li>
                <FontAwesomeIcon icon="check" />
                <strong>Competitive Pricing</strong> Maxpower Limited have
                engineer bases all over the UK, enabling us to offer industry
                leading pricing for Fixed Wire Testing.
              </li>
            </ul>

            <Accreditations />
          </div>
          <div className="d-none d-lg-block col-lg-3 offset-lg-1">
            <Sidebar title={meta.title} />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default EicrAndEmergencyLightTestingInBuckinghamshirePage
